<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">
    <a-alert
      v-if="showAlert"
      :message="
        lang == 'uz'
          ? 'Login yoki parol xato !'
          : 'Логин или пароль был введен неверно'
      "
      type="error"
    />
    <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
      <!-- Sign In Form Column -->
      <a-col
        :span="24"
        :md="12"
        :lg="{ span: 12, offset: 0 }"
        :xl="{ span: 6, offset: 2 }"
        class="col-form"
      >
        <h1 class="mb-15" style="font-size: 44px !important">
          {{ lang == "uz" ? "Tizimga kirish" : "Вход в систему" }}
        </h1>
        <h5 class="font-regular text-muted">
          {{
            lang == "uz"
              ? "Login va parolingizni kiriting"
              : "Введите логин и пароль"
          }}
        </h5>

        <!-- Sign In Form -->
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <!-- v-decorator="['username',	{ rules: [{ required: false, message: 'Iltimos loginni kiriting!' }] },	]" -->
          <a-form-item
            class="mb-10"
            :label="lang == 'uz' ? 'Login' : 'Логин'"
            :colon="false"
          >
            <a-input
              v-model="username"
              :placeholder="lang == 'uz' ? 'Login' : 'Логин'"
            />
          </a-form-item>
          <a-form-item
            class="mb-5"
            :label="lang == 'uz' ? 'Parol' : 'Пароль'"
            :colon="false"
          >
            <!-- v-decorator="['password',{ rules: [{ required: true, message: 'Iltimos parolni kiriting' }] },]" -->
            <a-input
              v-model="password"
              type="password"
              :placeholder="lang == 'uz' ? 'Parol' : 'Пароль'"
            />
          </a-form-item>
          <a-form-item class="mb-10">
            <a-switch v-model="rememberMe" />
            <span v-if="lang == 'uz'">Eslab qolish</span>
            <span v-if="lang == 'ru'">Запомнить</span>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
              @click="checkUser()"
            >
              {{ lang == "uz" ? "TIZIMGA KIRISH" : "ВОЙТИ" }}
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->

        <!-- <p class="font-semibold text-muted">
          Akkauntingiz mavjud emasmi?
          <router-link to="/sign-in" class="font-bold text-dark"
            >Ro'yxatdan o'tish</router-link
          >
        </p> -->
      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img img_centered">
        <img src="images/image_clinic.png" alt="" />
      </a-col>
      <!-- Sign In Image Column -->
    </a-row>
  </div>
</template>

<script>
import axios from "../axios.js";
import router from "../router/index";
export default {
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      username: null,
      password: null,
      formData: {
        username: null,
        password: null,
      },
      showAlert: false,
      lang: "uz",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang") || "uz";
    //   axios.defaults.headers.common[
    //     "Authorization"
    //   ] = `Bearer ${localStorage.getItem("access_token")}`;
    //   axios.get("/api/users").then((res) => {
    //     if (res.status == 200) {
    //       router.replace("/dashboard");
    //     }
    //   });
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    checkUser() {
      axios
        .post("/api/auth/login", {
          username: this.username,
          password: this.password,
        })
        .then((data) => {
          let userData = this.$jwt.decode(data.data);
          localStorage.setItem("access_token", data.data);
          localStorage.setItem("user_id", userData.sub);
          localStorage.setItem("user_name", userData.user.username);
          localStorage.setItem("user_role", userData.user.role);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.data}`;
          if (userData.user.role == "ADMIN") {
            router.replace("/dashboard");
          } else if (userData.user.role == "REGISTRATION") {
            axios.get("/api/registrations").then((res) => {
              let regArr = res.data._embedded.registrations;
              for (let t = 0; t < regArr.length; t++) {
                if (regArr[t].userId == userData.sub) {
                  let userLink = regArr[t]._links.self.href;
                  let role_id = userLink.substring(
                    userLink.lastIndexOf("/") + 1,
                    userLink.length
                  );
                  localStorage.setItem("role_id", role_id);
                  router.replace("/patients");
                }
              }
            });
          } else if (userData.user.role == "LABORATORY") {
            axios.get("/api/laboratories").then((res) => {
              let regArr = res.data._embedded.laboratories;
              for (let t = 0; t < regArr.length; t++) {
                if (regArr[t].userId == userData.sub) {
                  let userLink = regArr[t]._links.self.href;
                  let role_id = userLink.substring(
                    userLink.lastIndexOf("/") + 1,
                    userLink.length
                  );
                  localStorage.setItem("role_id", role_id);
                  router.replace("/laboratory");
                }
              }
            });
          } else {
            axios.get("/api/doctors").then((res) => {
              let regArr = res.data._embedded.doctors;
              for (let t = 0; t < regArr.length; t++) {
                if (regArr[t].userId == userData.sub) {
                  let userLink = regArr[t]._links.self.href;
                  let role_id = userLink.substring(
                    userLink.lastIndexOf("/") + 1,
                    userLink.length
                  );
                  localStorage.setItem("role_id", role_id);
                  router.replace("/analysis");
                }
              }
            });
          }
        })
        .catch(() => {
          this.showAlert = true;
        });
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
.img_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>